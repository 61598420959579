import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player'

function Games() {

  const [isGames, setGames] = useState([]);

  useEffect(() => {
    const getGames = async () => {
      const data = await axios.get(`${process.env.REACT_APP_BACK_API}/games`);
      setGames(data.data.data);
    };
    getGames();
  }, []);

  return (
    <div className="games-page">
      <div className="tc-container">
        <div className="games-list">
          {isGames.map((item, k) => (
            <div className={`games-list_item ${item.video ? `grid-2` : `grid-1`} `} key={k}>
              {item.video ? 
                <div className="games-list_itemMedia">
                  <ReactPlayer url={process.env.REACT_APP_IMAGE_PATH + item.video} controls width='100%' height='auto'/>
                </div> : null}
              <div className="games-list_itemBody">
                <div className="games-list_itemBodyTitle">{item.title}</div>
                <div className="games-list_itemBodyText" dangerouslySetInnerHTML={{ __html: item.description }}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Games;
  