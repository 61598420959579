import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cover from "../components/Cover";
import axios from 'axios';

function Comic() {

  const [isComics, setComics] = useState([]);

  useEffect(() => {
    const getComics = async () => {
      const data = await axios.get(`${process.env.REACT_APP_BACK_API}/comics`);
      setComics(data.data.data);
    };
    getComics();
  }, []);

  return (
    <div className="comic">
      <Cover/>
      <div className="comic-section">
        <div className="tc-container">
          <h2 className='comic-section--title'>COMICS</h2>
          <div className="comic-section--list">
            {isComics.map((item, k) => (
              <div className="comic-section--list_item" key={k}>
                <div className="comic-section--list_itemImage">
                  <img className="lazyload" data-src={process.env.REACT_APP_IMAGE_PATH + item.cover} alt={item.name} />
                </div>
                <Link to={`/comic/${item.slug}`} className='comic-section--list_itemLink'><span>Унших</span></Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default Comic;
  