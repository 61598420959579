import React from 'react';
import Cover from '../components/Cover';
import Usage from '../components/Usage';
import HomeDetail from './HomeDetail';
import HomeProductNews from './HomeProductNews';

function Home() {
    return (
      <div className="home">
        <Cover/>
        <div className="home-bottom">
          <Usage/>
          <HomeDetail/>
        </div>
        <HomeProductNews/>
      </div>
    );
}
  
export default Home;
  