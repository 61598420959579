import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function ComicRead() {
    const { slug } = useParams();
    const [isComic, setComic] = useState([]);
    const [isGallery, setGallery] = useState([]);

    useEffect(() => {
      const getComic = async () => {
        const data = await axios.get(`${process.env.REACT_APP_BACK_API}/comics/${slug}`);
        setComic(data.data.data);
        setGallery(JSON.parse(data.data.data.gallery));
      };
      getComic();
    }, [slug]);
    
    return (
        <div className="comic-read">
            <div className="tc-container">
                <div className="comic-read_card">
                    <div className="comic-read_cardCover">
                        {isComic.cover && (<img className="lazyload" data-src={process.env.REACT_APP_IMAGE_PATH + isComic.cover} alt={isComic.name} />)}
                    </div>
                    <div className="comic-read_cardBody">
                        <h2 className="comic-read_cardBodyTitle">{isComic.name}</h2>
                        {isComic.body ? <div className="comic-read_cardBodyDescription" dangerouslySetInnerHTML={{ __html: isComic.body }}></div> : null}
                    </div>
                </div>
                <div className="comic-read_gallery">
                    {isGallery && isGallery.map((item, k) => (
                        <div className="comic-read_galleryItem" key={k}>
                            <img className="lazyload" data-src={process.env.REACT_APP_IMAGE_PATH + item} alt={isComic.name} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
  
export default ComicRead;
  