import React from 'react';
import {ReactComponent as LogoSvg} from '../assets/image/logo.svg';

function Loading() {
  return (
    <div className='loading-page'><div><LogoSvg/></div></div>
  );
}
  
export default Loading;
  