import React, { useState, useEffect } from 'react';
import { useAxios } from 'use-axios-client';
import axios from 'axios';
import moment from 'moment'
import CountdownTimer from '../components/CountdownTimer';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Loading from "../elements/Loading";

function Event() {

    const { loading } = useAxios({
        url: `${process.env.REACT_APP_BACK_API}/events`,
    });
    
    const [isEvents, setEvents] = useState([]);
    const [isComingEvent, setComingEvent] = useState([]);

    useEffect(() => {
      const getEvents = async () => {
        const data = await axios.get(`${process.env.REACT_APP_BACK_API}/events`);
        setEvents(data.data.data);
      };
      getEvents();
      const getFirstEvent = async () => {
        const firstData = await axios.get(`${process.env.REACT_APP_BACK_API}/events`);
        const firstElement = firstData.data.data.shift();
        setComingEvent(firstElement);
      };
      getFirstEvent();
    }, []);

    return (
      <div className="event-page">
        {loading && <Loading/>}
        <div className="event-page--map">
            <iframe title={isComingEvent.name} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1298.750708903375!2d107.14136068444867!3d47.9245057985182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9684da515409ad%3A0xd242c5ba95df049c!2sMongol%20Shiltgeen!5e0!3m2!1sen!2smn!4v1655456966760!5m2!1sen!2smn" allowFullScreen="allowFullScreen" loading="lazy" />
        </div>
        <div className="event-page--current">
            <div className="tc-container">
                <div className="event-page--current_card">
                    <div className="event-page--current_cardDetail">
                        <h2 className="event-page--current_cardDetailTitle">{isComingEvent.name}</h2>
                        <div className="event-page--current_cardDetailDesc" dangerouslySetInnerHTML={{ __html: isComingEvent.body }}></div>
                        <div className="event-page--current_cardDetailSocial">
                            <TwitterShareButton
                                title={isComingEvent.name}
                                url={"https://tailagch.mn/events"}
                            >
                                <div className='social-share tw'></div>
                            </TwitterShareButton>
                            <FacebookShareButton
                                url={"https://tailagch.mn/events"}
                                className="Demo__some-network__share-button"
                            >
                                <div className='social-share fb'></div>
                            </FacebookShareButton>
                        </div>
                    </div>
                    <div className="event-page--current_cardImage">
                        {isComingEvent.image && (<img className="lazyload" data-src={process.env.REACT_APP_IMAGE_PATH + isComingEvent.image} alt={isComingEvent.name} />)}
                    </div>
                </div>
            </div>
            <div 
                className="event-page--current_date" 
                style={{  
                    backgroundImage: `url(../image/event/date_back.png)`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <div className="event-page--current_dateTitle">
                    <img className='lazyload' data-src="../image/event/date_tt.png" alt="Events" />
                </div>
                <div className="event-page--current_dateDetail">
                    <div className="countdown">
                        <div className="countdown-date">{moment(isComingEvent.date).format('M-D')}</div> 
                        <CountdownTimer countdownTimestampMs={Date.parse(moment(isComingEvent.date).format('YYYY-MM-DD'))}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="event-page--more">
            <div className="tc-container">
                <h2 className="event-page--more_title">MORE EVENTS</h2>
                <div className="event-page--more_list">
                    {isEvents.map((item, k) => (
                        <div className="event-page--more_listItem" key={k}>
                            <img className="lazyload" data-src={process.env.REACT_APP_IMAGE_PATH + item.image} alt={item.name} />
                            <a href={item.link}><span>More</span></a>
                        </div>
                    ))}
                </div>
                <div className="event-page--more_pagination">
                    <div className="event-page--more_paginationItem">
                        <button><i className="arrow-top"></i></button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
}
  
export default Event;
  