import React from 'react';
import { Link } from 'react-router-dom';
import RellaxWrapper from 'react-rellax-wrapper'

function Cover() {
    return (
        <div className="cover">
            <div className="cover-detail">
                <div className="cover-detail_1">
                    <img className="lazyload" data-src="../image/cover/cover_layer1.png" alt="Khandgai Tailagch" />
                </div>
                <div className="cover-detail_2 absolute">
                    <img className="lazyload" data-src="../image/cover/cover_layer2.png" alt="Khandgai Tailagch" />
                </div>
                <div className="cover-detail_3 absolute">
                    <img className="lazyload" data-src="../image/cover/cover_layer3.png" alt="Khandgai Tailagch" />
                </div>
            </div>
            <div className="cover-image">
                <div className="cover-image_1 absolute">
                    <RellaxWrapper speed={3}>
                        <img className="lazyload" data-src="../image/cover/cover_image1.png" alt="Khandgai Tailagch" />
                    </RellaxWrapper>
                </div>
                <div className="cover-image_2 absolute">
                    <RellaxWrapper speed={3}>
                        <img className="lazyload" data-src="../image/cover/cover_image2.png" alt="Khandgai Tailagch" />
                    </RellaxWrapper>
                </div>
                <div className="cover-image_3 absolute">
                    <img className="lazyload" data-src="../image/cover/cover_image3.png" alt="Khandgai Tailagch" />
                </div>
                <div className="cover-image_4 absolute">
                    <img className="lazyload" data-src="../image/cover/cover_image4.png" alt="Khandgai Tailagch" />
                </div>
            </div>
            <div className="cover-left">
                <div className="cover-left_1 absolute">
                    <img className="lazyload" data-src="../image/cover/cover_left1.png" alt="Khandgai Tailagch" />
                </div>
                <div className="cover-left_2 absolute">
                    <img className="lazyload" data-src="../image/cover/cover_left2.png" alt="Khandgai Tailagch" />
                </div>
            </div>
            <div className="cover-right">
                <div className="cover-right_1 absolute">
                    <RellaxWrapper speed={-5}>
                        <img className="lazyload" data-src="../image/cover/cover_right1.png" alt="Khandgai Tailagch" />
                    </RellaxWrapper>
                </div>
                <div className="cover-right_2 absolute">
                    <RellaxWrapper speed={6}>
                        <img className="lazyload" data-src="../image/cover/cover_right2.png" alt="Khandgai Tailagch" />
                    </RellaxWrapper>
                </div>
                <div className="cover-right_3 absolute">
                    <RellaxWrapper speed={6}>
                        <img className="lazyload" data-src="../image/cover/cover_right3.png" alt="Khandgai Tailagch" />
                    </RellaxWrapper>
                </div>
                <div className="cover-right_4 absolute">
                    <img className="lazyload" data-src="../image/cover/cover_right4.png" alt="Khandgai Tailagch" />
                </div>
            </div>
            <div className="cover-link">
                <Link to='/'><span>Цааш унших</span></Link>
            </div>
        </div>
    );
}
  
export default Cover;
  