import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

function HomeProductNews() {
    const [homeProducts, setHomeProducts] = useState([]);
    const [homeNews, setHomeNews] = useState([]);

    useEffect(() => {
        const getHomeProducts = async () => {
            const data = await axios.get(`${process.env.REACT_APP_BACK_API}/home_products`);
            setHomeProducts(data.data.data);
        };
        const getHomeNews = async () => {
            const data = await axios.get(`${process.env.REACT_APP_BACK_API}/home_news`);
            setHomeNews(data.data.data);
        };
        getHomeProducts();
        getHomeNews();
    }, []);

    return (
        <div className="home-plus">
            <div className="home-product">
                <h3 className="home-product_tt">Бүтээгдэхүүн</h3>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={24}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        768: {
                          slidesPerView: 3,
                        },
                        1024: {
                          slidesPerView: 3,
                        },
                    }}
                    modules={[Pagination]}
                    className="homeSwiper"
                >
                    {homeProducts.map((item, k) => (
                        <SwiperSlide key={k}>
                            <div className="image">
                                <img className="lazyload" data-src={process.env.REACT_APP_IMAGE_PATH + item.image} alt={item.name} />
                                <p className="title">{item.name}</p>
                            </div>
                            <a href={item.link} className="link-btn">{item.price}</a>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="home-news">
                <h3 className="home-product_tt">Мэдээ мэдээлэл</h3>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={24}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        768: {
                          slidesPerView: 2,
                        },
                        1024: {
                          slidesPerView: 3,
                        },
                    }}
                    modules={[Pagination]}
                    className="newsSwiper"
                >
                    {homeNews.map((item, k) => (
                        <SwiperSlide key={k}>
                            <div className="image">
                                <img className="lazyload" data-src={process.env.REACT_APP_IMAGE_PATH + item.image} alt={item.name} />
                                <p className="title">{item.name}</p>
                            </div>
                            <a href={item.link} className="link-btn">Дэлгэрэнгүй</a>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}
  
export default HomeProductNews;
  