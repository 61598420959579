import React from 'react';

function About() {
  return (
    <div className="coming-soon">
      <img src="../image/coming-soon.png" alt="Tailgach || Khandgai" />
      <p>COMING SOON</p>
    </div>
  );
}
  
export default About;
  