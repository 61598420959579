import React, { useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import {ReactComponent as LogoSvg} from '../assets/image/logo.svg';

function Header() {
  const [isMobileOpen, setMobileOpen] = useState(false);

  const mobileMenuOpen = () => {
    setMobileOpen(!isMobileOpen);
  };

  return (
    <header className={isMobileOpen ? 'header open': 'header'}>
      <div className="tc-container flex">
        <div className='header-logo'>
          <Link to='/'>
            <LogoSvg/>
          </Link>
        </div>
        <div className='header-menu'>
          <ul className='list-none'>
            <li><NavLink activeclassname="active" to="/">Home</NavLink></li>
            <li><NavLink activeclassname="active" to="/comic">Comic Story</NavLink></li>
            <li><NavLink activeclassname="active" to="/event">Events</NavLink></li>
            <li><NavLink activeclassname="active" to="/games">Games</NavLink></li>
          </ul>
        </div>
        <div className="header-menu_mobile">
          <div className={isMobileOpen ? 'burger-menu burger-menu--opened': 'burger-menu burger-menu--closed'} onClick={mobileMenuOpen}>
            <div className="burger-bar"></div>
            <div className="burger-bar"></div>
            <div className="burger-bar"></div>
          </div>  
          <div className={isMobileOpen ? 'header-menu_mobileBack open': 'header-menu_mobileBack'} onClick={mobileMenuOpen}></div>
          <div className={isMobileOpen ? 'header-menu_mobileItem open': 'header-menu_mobileItem'}>
            <ul className='list-none'>
              <li onClick={mobileMenuOpen}><NavLink activeclassname="active" to="/">Home</NavLink></li>
              <li onClick={mobileMenuOpen}><NavLink activeclassname="active" to="/comic">Comic Story</NavLink></li>
              <li onClick={mobileMenuOpen}><NavLink activeclassname="active" to="/event">Events</NavLink></li>
              <li onClick={mobileMenuOpen}><NavLink activeclassname="active" to="/games">Games</NavLink></li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
  
export default Header;
  