import React from 'react';

function NotFound() {
  return (
    <div className='error'>
      <div title="404 PAGE NOT FOUND" className='error-page'>404 PAGE NOT FOUND</div>
    </div>
  );
}
  
export default NotFound;
  