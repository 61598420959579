import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {ReactComponent  as LogoSvg} from '../assets/image/logo.svg';
import {ReactComponent  as AddressSvg} from '../assets/image/address.svg';
import {ReactComponent  as MailSvg} from '../assets/image/mail.svg';
import {ReactComponent  as PhoneSvg} from '../assets/image/phone.svg';

function Footer() {
  return (
    <footer className='footer'>
      <div className="tc-container">
        <div className="footer-logo">
            <Link to='/'>
                <LogoSvg/>
            </Link>
        </div>
        <div className="footer-menu">
            <ul className='list-none'>
                <li><NavLink to="/about">About us</NavLink></li>
                <li><NavLink to="/contact">Contact us</NavLink></li>
                <li><NavLink to="/event">Events</NavLink></li>
                <li><NavLink to="/games">Games</NavLink></li>
            </ul>
        </div>
        <div className="footer-contact">
            <div className="footer-contact_list">
                <div className="footer-contact_listItem">
                    <div><AddressSvg/></div>
                    <span>Сонсголонгийн зам гудамж, 32/3 тоот, <br/>Үйлдвэрийн баруун бүс, БГД 20-р хороо, <br/>Улаанбаатар, 16103, Монгол улс</span>
                </div>
            </div>
            <div className="footer-contact_list">
                <div className="footer-contact_listItem">
                    <div><MailSvg/></div>
                    <span>INFO@MONOSFOODS.MN</span>
                </div>
                <div className="footer-contact_listItem">
                    <div><PhoneSvg/></div>
                    <span>+976 7777-3525</span>
                </div>
            </div>
        </div>
      </div>
      <div className="footer-social">
            <ul className='list-none'>
                <li><Link to="/"><div className='footer-social_logo tw'></div></Link></li>
                <li><Link to="/"><div className='footer-social_logo ytb'></div></Link></li>
                <li><Link to="/"><div className='footer-social_logo inst'></div></Link></li>
                <li><Link to="/"><div className='footer-social_logo fb'></div></Link></li>
                <li><Link to="/"><div className='footer-social_logo in'></div></Link></li>
            </ul>
        </div>
    </footer>
  );
}
  
export default Footer;
  