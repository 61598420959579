import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Usage() {

  const [threeProduct, setThreeProduct] = useState([]);

  useEffect(() => {
    const getThreeProduct = async () => {
      const data = await axios.get(`${process.env.REACT_APP_BACK_API}/three_product`);
      setThreeProduct(data.data.data);
    };
    getThreeProduct();
  }, []);

  return (
    <div className="usage">
      <div className="tc-container">
        <div className="usage-list" uk-scrollspy="cls: uk-animation-slide-left-medium; target: .usage-list_item; delay: 400; repeat: false">
          {threeProduct && threeProduct.map((item, k) => (
            <div className="usage-list_item" key={k}>
              <div className="detail">
                <p className="number">{item.name}</p>
                <div className="desc" dangerouslySetInnerHTML={{ __html: item.body }}></div>
              </div>
              <div className="img">
                <img className="lazyload" data-src={process.env.REACT_APP_IMAGE_PATH + item.image} alt={item.body} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
  
export default Usage;
  