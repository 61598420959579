import React from 'react';

function HomeDetail() {
    return (
        <>
            <div className="home-bottom_section">
                <div className="bottom-back">
                <img src="../image/homepage/home_back.png" alt="Tailagch" className='bottom-back_img' />
                <div className="loop_tailagch">
                    <img src="../image/homepage/tailagch_back.png" alt="Tailagch" />
                </div>
                </div>
                <img src="../image/homepage/pattern.png" alt="Tailagch" className='pattern' />
                <div className="pink_box"></div>
            </div>
            <div className="home-bottom_detail">
                <img src="../image/homepage/tailagch_white.png" alt="Tailagch" className='layer1' />
                <img src="../image/homepage/tailagch_black.png" alt="Tailagch" className='layer2' />
            </div>
            <div className="home-bottom_mainimage">
                <iframe src="https://www.youtube.com/embed/videoseries?list=PL6ucekIF5g_v4pi3cTlyVVjW7Ivp7evIY" title="Tailagch" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <div className="home-bottom_text">
                <h4>ХОРДЛОГООС СЭРГИЙЛЖ, <span>ШАР ТАЙЛНА</span></h4>
                <p>
                Архинаас үүдэлтэй хордлогоос сэргийлж, шар тайлах үйлчилгээтэй “ТАЙЛАГЧ”<br/>
                бүтээгдэхүүнийг Эконте ХХК-ийн Хандгай төсөлтэй хамтран цоо шинээр худалдаанд гаргаж<br/>
                байна. Энэхүү бүтээгдэхүүний худалдан авалт бүр Монголын бүтээлч салбарт хувь нэмэр<br/>
                оруулах болно.<br/><br/>
                <strong>Найрлага:</strong> Албарина өтгөрүүлсэн ханд /Lonicera japonica, Common reed/, Монгол зөгийн бал,<br/>
                ногоон чавганы өтгөн ханд, таурин, витамин C, цэвэршүүлсэн ус<br/><br/>
                <span>ХЭЗЭЭ УУХАА ӨӨРӨӨ ШИЙД</span><br/><br/>
                Хэн, хэзээ хэрэглэх вэ? <br/>
                • Шартахаас эмээдэг, өөртөө хайртай, БЭЛЭН байхыг хүссэн хүн бүр
                </p>
            </div>
            <div className="home-bottom_sekhuun">
                <img src="../image/homepage/sekhuun.png" alt="Tailagch" />
            </div>
        </>
    );
}
  
export default HomeDetail;
  