import React, { useEffect, useState } from "react";
import Home from './pages/Home';
import Blog from './pages/Blog';
import Comic from './pages/Comic';
import About from './pages/About';
import Brand from './pages/Brand';
import Event from './pages/Event';
import Games from "./pages/Games";
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import Header from "./elements/Header";
import Footer from "./elements/Footer";
import ComicRead from "./pages/ComicRead";
import ScrollTop from "./components/ScrollTop";
import AnimatedCursor from "react-animated-cursor";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAxios } from 'use-axios-client';
import Loading from "./elements/Loading";

function RoutePage() {

  const [isVisible, setIsVisible] = useState(false);

  const { loading } = useAxios({
    url: `${process.env.REACT_APP_BACK_API}/comics`,
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <Router>
      <div className="main">
        <Header/>
        <AnimatedCursor
          innerSize={6}
          outerSize={24}
          color='193, 11, 111'
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={2}
        />
        <div className="main-content">
          {loading && <Loading/>}
          <ScrollTop/>
          <Routes>
            <Route path="/" element={<Home/>} exact />
            <Route path="/blog" element={<Blog/>} exact />
            <Route path="/brand" element={<Brand/>} exact />
            <Route path="/comic" element={<Comic/>} exact />
            <Route path="/comic/:slug" element={<ComicRead/>} exact />
            <Route path="/event" element={<Event/>} exact />
            <Route path="/about" element={<About/>} exact />
            <Route path="/contact" element={<Contact/>} exact />
            <Route path="/games" element={<Games/>} exact />
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </div>
        <Footer/>
        <div className="scroll-to-top">
          {isVisible && (
            <button onClick={scrollToTop}>
              <i className="arrow-top"></i>
            </button>
          )}
        </div>
      </div>
    </Router>
  );
}
 
export default RoutePage;
  